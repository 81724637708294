import React from "react";
import Metatags from "../common/Metatags"
import Slider from "../components/Slider";
import Services from "../components/Services";
import AwesomeFeature from "../components/AwesomeFeature";
import AboutBusiness from "../components/AboutBusiness";
import RecentProject from "../components/RecentProject";
import Testimonial from "../components/Testimonial";
import PortfolioAd from "../components/portfolioAd";


function Home() {
  return (
    <>
        {/* <Metatags pageName="home" /> */}
        <Slider/>
        <div className="main-content">
            <Services/>
            <AboutBusiness/>
            <RecentProject/>
            <AwesomeFeature/>
            <Testimonial/>
            <PortfolioAd/>
        </div>
      
    </>
  );
}

export default Home;
