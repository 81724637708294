import React from 'react'
import Metatags from "../../common/Metatags";
import Atropos from 'atropos/react';
import { Link } from "react-router-dom";

function GraphicDesignService() {
  return (
    <>
    {/* <Metatags pageName="graphic-and-logo-design" /> */}

   {/* Start Slider Area*/}
   <div className="slider-area slider-style-1 variation-3 bg-transparent rainbow-section-gap">
       <div className="container">
           <div className="row">
               <div className="col-lg-12">
                   <div className="inner text-center">
                       <h1 className="display-two">Welcome to <strong className='text-green'>ITSS <br/> Graphic</strong> and Logo Design <br></br>Services</h1>
                       <p className="description">At ITSS, we craft memorable graphic designs and logos that capture your brand essence. Let our eye-catching creations elevate your business and leave a lasting impression.</p>
                   </div>
               </div>
           </div>
           <div className="row">
               <div className="col-lg-12">
                   <div className="rainbow-default-tab style-two rbt-alignwide">
                       <div className="rbt-inner-alignwide pb--60">

                           <ul className="nav nav-tabs tab-button justify-content-center mb--50" role="tablist">
                               <li className="nav-item tabs__tab" role="presentation">
                                   <button className="nav-link active" id="irin-tab" data-bs-toggle="tab" data-bs-target="#irin" type="button" role="tab" aria-controls="irin" aria-selected="true">Logo</button>
                               </li>

                               <li className="nav-item tabs__tab" role="presentation">
                                   <button className="nav-link" id="nipa-tab" data-bs-toggle="tab" data-bs-target="#nipa" type="button" role="tab" aria-controls="nipa" aria-selected="false">Stationary</button>
                               </li>

                               <li className="nav-item tabs__tab" role="presentation">
                                   <button className="nav-link" id="supa-tab" data-bs-toggle="tab" data-bs-target="#supa" type="button" role="tab" aria-controls="supa" aria-selected="false">Ad. posts</button>
                               </li>

                               <li className="nav-item tabs__tab" role="presentation">
                                   <button className="nav-link" id="banner-tab" data-bs-toggle="tab" data-bs-target="#banner" type="button" role="tab" aria-controls="supa" aria-selected="false">Banner</button>
                               </li>
                           </ul>
                           <div className="rainbow-tab-content tab-content text-center rainbow-no-padding">
                               <div className="tab-pane fade  active show" id="irin" role="tabpanel" aria-labelledby="irin-tab">
                                   <Atropos>
                                   <div className="thambnail">
                                       <img loading="lazy" className="radius" src="assets/images/website/logo.png" alt="tab"/>
                                   </div>
                                   </Atropos>
                               </div>

                               <div className="tab-pane fade" id="nipa" role="tabpanel" aria-labelledby="profile-tab">
                                   <Atropos>
                                   <div className="thambnail">
                                       <img loading="lazy" className="radius" src="assets/images/website/Stationary.png" alt="tab"/>
                                   </div>
                                   </Atropos>
                               </div>

                               <div className="tab-pane fade" id="supa" role="tabpanel" aria-labelledby="contact-tab">
                                 <Atropos>
                                   <div className="thambnail">
                                       <img loading="lazy" className="radius" src="assets/images/website/Ad-post.png" alt="tab"/>
                                   </div>
                                   </Atropos>
                               </div>

                               <div className="tab-pane fade" id="banner" role="tabpanel" aria-labelledby="banner-tab">
                                 <Atropos>
                                   <div className="thambnail">
                                       <img loading="lazy" className="radius" src="assets/images/website/banner-social.png" alt="tab"/>
                                   </div>
                                   </Atropos>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
   {/* End Slider Area */}

   {/* Start Feature Area */}
   <div className="rbt-feature-area rainbow-section-gapBottom">
       <div className="container">
           <div className="row mb--40">
               <div className="col-lg-12">
                   <div className="section-title max-width-800 text-left">
                       <h4 className="text-green">Popular Feature</h4>
                       <h2 className="title w-600 mb--20">ITSS Crafting Creative Graphics for Your Business</h2>
                       <p className="description b1">At ITSS, we specialize in crafting creative graphic solutions designed to enhance your brand's visual identity. Our team blends imagination, technical expertise, and cutting-edge tools to deliver bespoke logos, branding elements, and marketing materials that make an impact. From concept to execution, we're committed to bringing your vision to life and helping your brand shine in the digital realm.
                       </p>
                   </div>
               </div>
           </div>
        
           <div className="feature-main">
              
               <div className="rbt-feature-main bg-lessdark-gradient mb-5 padding-top-left-50">
                   <div className="row">
                       <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                           <div className="feature-left-top">
                               <h3 >Define Your Brand Identity with ITSS Logo Design</h3>
                               <p>Your logo is the face of your brand. At ITSS, we specialize in crafting unique and memorable logos that capture the essence of your business. Our creative team collaborates closely with you to understand your vision and create a logo that sets you apart from the competition.</p>
                           </div>
                           <div className="feature-left-bottom">
                               <div className="button-group">
                                   <a className="btn-default btn-small btn-border" href="#">#LogoDesign</a>
                                   <a className="btn-default btn-small btn-border" href="#">#BrandIdentity </a>
                                   
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-6 col-md-12 col-sm-12">
                           <div className="thumbnail">
                           <Atropos>
                               <img loading="lazy" src="assets/images/website/laptop-logo.png" alt=""/>
                               </Atropos>
                           </div>
                       </div>
                   </div>
               </div>
              
               
               <div className="rbt-feature-main bg-lessdark-gradient mb-5 padding-top-left-50">
                   <div className="row">
                       <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                           <div className="feature-left-top">
                               <h3 >Elevate Your Brand's Image with ITSS Stationery Design</h3>
                               <p>Consistency is key to building a strong brand identity. ITSS offers stationery design services to ensure your brand message is conveyed seamlessly across all touchpoints. From business cards to letterheads, we create cohesive and professional stationery designs that leave a lasting impression.</p>
                           </div>
                           <div className="feature-left-bottom">
                               <div className="button-group">
                                   <a className="btn-default btn-small btn-border" href="#">#Stationery</a>
                                   <a className="btn-default btn-small btn-border" href="#">#ProfessionalIdentity</a>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-6 col-md-12 col-sm-12">
                           <div className="thumbnail">
                           <Atropos>
                               <img loading="lazy" src="assets/images/website/laptop-stationary.png" alt=""/>
                               </Atropos>
                           </div>
                       </div>
                   </div>
               </div>
               
               
               <div className="rbt-feature-main bg-lessdark-gradient mb-5 padding-top-left-50">
                   <div className="row">
                       <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                           <div className="feature-left-top">
                               <h3 >Catch Your Audience's Attention with ITSS Advertisement Posts</h3>
                               <p>In today's digital world, eye-catching advertisements are essential for grabbing attention and driving engagement. At ITSS, we specialize in designing captivating advertisement posts that effectively communicate your message and compel action. Let us help you stand out in the crowded digital landscape.</p>
                           </div>
                           <div className="feature-left-bottom">
                               <div className="button-group">
                                   <a className="btn-default btn-small btn-border" href="#">#DigitalMarketing</a>
                                   <a className="btn-default btn-small btn-border" href="#">#EngagementBoost</a>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-6 col-md-12 col-sm-12">
                           <div className="thumbnail">
                           <Atropos>
                               <img loading="lazy" src="assets/images/website/laptop-advt.png" alt=""/>
                               </Atropos>
                           </div>
                       </div>
                   </div>
               </div>
               
               
               <div className="rbt-feature-main bg-lessdark-gradient mb-5 padding-top-left-50">
                   <div className="row">
                       <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                           <div className="feature-left-top">
                               <h3 >Make a Statement with ITSS Banner and Flyer Design</h3>
                               <p>Whether it's for a trade show, event, or promotional campaign, banners and flyers are powerful marketing tools. ITSS offers bespoke banner and flyer design services to help you make a statement and attract your target audience's attention. Let us turn your ideas into visually stunning designs that get results.</p>
                           </div>
                           <div className="feature-left-bottom">
                               <div className="button-group">
                                   <a className="btn-default btn-small btn-border" href="#">#BannerDesign</a>
                                   <a className="btn-default btn-small btn-border" href="#">#FlyerDesign</a>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-6 col-md-12 col-sm-12">
                           <div className="thumbnail">
                           <Atropos>
                               <img loading="lazy" src="assets/images/website/laptop-banner.png" alt=""/>
                               </Atropos>
                           </div>
                       </div>
                   </div>
               </div>
               

           </div>

           <div className="mt--60 text-center">
               <Link className="btn-default btn-large btn-border" to={"/graphic-portfolio"}>View portfolio <i className="feather-arrow-right"></i></Link>
               <p className="mt--30 mb--0 text-white">Explore Our Diverse Portfolio of Creative Excellence.
               </p>
           </div>
       </div>
   </div>
   {/* End Feature Area */}

   {/* Start FAQ area */}
   <div className="rbt-separator-mid">
            <div className="container">
                <hr className="rbt-separator m-0"/>
            </div>
        </div>

        <div className="rainbow-accordion-area preview-accordion-area rainbow-section-gap ">
            <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div className="row row--30">
                    <div className="col-lg-12 col-sm-12">
                        <div className="section-title text-start mb--60 sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
                            
                            <h4 className="subtitle "><span className="theme-gradient">Have a Question?</span></h4>
                            <h2 className="title w-600 mb--20">Check out our FAQ section <br/> to see if we can help.</h2>
                        </div>
                        <div className="rainbow-accordion-style  accordion">
                            <div className="accordion" id="accordionExamplea">
                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        What makes a good logo design?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExamplea" >
                                        <div className="accordion-body card-body">
                                        A good logo design is simple, memorable, and reflective of your brand's identity. It should be versatile and effective across various platforms and sizes.

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How do you ensure brand consistency in graphic design?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">
                                        Brand consistency is maintained through a unified color palette, typography, and design elements. We create a brand style guide to ensure all visuals align with your brand identity.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">What is the typical turnaround time for a graphic design project?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">
                                        Turnaround times can vary based on the complexity of the project. We strive to deliver quality designs efficiently and will provide a clear timeline during the project discussion phase.</div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Do you offer revisions for graphic designs?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">Yes, we value client input. We offer a specified number of revisions to ensure the final design meets your expectations and aligns with your vision.</div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Can you design graphics for social media platforms?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">Absolutely. Our graphic design services include creating visuals tailored for various social media platforms, ensuring your brand remains consistent across all your online channels.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   {/* Start FAQ area */}
</>
  )
}

export default GraphicDesignService