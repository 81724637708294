import React,{useState} from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [activeItem, setActiveItem] = useState('home');
  const handleItemClick = (item,run = true) => {
    setActiveItem(item);
    if(run){
        toggleMenu();
    }
    
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle the menu state
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
        <header className="rainbow-header header-default header-not-transparent header-sticky">
            <div className="container position-relative">
                <div className="row align-items-center row--0">
                    <div className="col-lg-3 col-md-6 col-4">
                        <div className="logo">
                            <Link to={"/"}>
                                <img className="logo-light" src="assets/images/logo/logo.png" width={150} alt="ITSS Logo"/>
                                <img className="logo-dark" src="assets/images/logo/logo-dark.png" alt="ITSS Logo"/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-6 col-8 position-static">
                        <div className="header-right">

                        <nav className="mainmenu-nav d-none d-lg-block">
                                <ul className="mainmenu">
                                    <li><Link to={"/"} className={activeItem === 'home' ? 'active' : ''} onClick={() => handleItemClick('home')}>Home</Link></li>
                                    <li><Link to={"/about"} className={activeItem === 'about' ? 'active' : ''} onClick={() => handleItemClick('about')}>About</Link></li>
                                    <li><Link to={"/services"} className={activeItem === 'services' ? 'active' : ''} onClick={() => handleItemClick('services')}>Our Services</Link>
                                    </li>
                                    <li className="has-droupdown has-menu-child-item"><Link to={"#"} className={activeItem === 'portfolio' ? 'active' : ''}>Portfolio</Link>
                                        <ul className="submenu">
                                            <li><Link to={"/website-portfolio"} onClick={() => handleItemClick('portfolio')}>Website Development</Link></li>
                                            <li><Link to={"/graphic-portfolio"} onClick={() => handleItemClick('portfolio')}>Graphic & Logo Design</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-droupdown has-menu-child-item"><Link to={"#"} className={activeItem === 'pricing' ? 'active' : ''}>Pricing</Link>
                                        <ul className="submenu">
                                            <li><Link to={"/website-pricing"} onClick={() => handleItemClick('pricing')}>Website Pricing</Link></li>
                                            <li><Link to={"/graphic-pricing"} onClick={() => handleItemClick('pricing')}>Graphic Pricing</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>

                            <div className="header-btn">
                                <Link className="btn-default btn-small round" to={"/contact"} onClick={() => handleItemClick('home',false)}>CONTACT NOW</Link>
                            </div>

                            <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                <div className="hamberger">
                                    <button className="hamberger-button" onClick={toggleMenu}>
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div className={isMenuOpen ? "popup-mobile-menu active": "popup-mobile-menu"}  >
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                            <Link to={"/"}>
                                <img className="logo-light" src="assets/images/logo/logo.png" width={150} alt="ITSS Logo"/>
                                <img className="logo-dark" src="assets/images/logo/logo-dark.png" alt="ITSS Logo"/>
                            </Link>
                    </div>
                    <div className="close-menu">
                        <button className="close-button" onClick={toggleMenu}>
                            <i className="feather-x"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    <li><Link to={"/"} className={activeItem === 'home' ? 'active' : ''} onClick={() => handleItemClick('home')}>Home</Link></li>
                    <li><Link to={"/about"} className={activeItem === 'about' ? 'active' : ''} onClick={() => handleItemClick('about') }>About</Link></li>
                    <li><Link to={"/services"} className={activeItem === 'services' ? 'active' : ''} onClick={() => handleItemClick('services')}>Our Services</Link></li>
                   
                    <li><Link to={"/website-portfolio"} className={activeItem === 'webportfolio' ? 'active' : ''} onClick={() => handleItemClick('webportfolio')}>Website Development Portfolio</Link></li>
                    <li><Link to={"/graphic-portfolio"} className={activeItem === 'gportfolio' ? 'active' : ''} onClick={() => handleItemClick('gportfolio')}>Graphic Design Portfolio</Link></li>

                    <li><Link to={"/graphic-pricing"} className={activeItem === 'gpricing' ? 'active' : ''} onClick={() => handleItemClick('gpricing')}>Graphic Pricing</Link></li>
                    <li><Link to={"/website-pricing"} className={activeItem === 'webpricing' ? 'active' : ''} onClick={() => handleItemClick('webpricing')}>Website Pricing</Link></li>
                    
                </ul>
            </div>
        </div>
        <div>
            <div className="rainbow-gradient-circle"></div>
            <div className="rainbow-gradient-circle theme-pink"></div>
        </div>

    </>
  );
}

export default Navbar;
