import React from 'react'

function Testimonial() {
  return (
    <>
    <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
        </div>
    <div className="rainbow-testimonial-area rainbow-section-gap" data-aos="fade-up">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
                        <h4 className="subtitle ">
                            <span className="theme-gradient">Client Feedback</span>
                        </h4>
                        <h2 className="title w-600 mb--20">Our Testimonial</h2>
                    </div>
                </div>
            </div>

            <div className="row row--15">
                <div className="col-lg-4 col-md-6 col-12 mt--30 sal-animate" data-sal="slide-up" data-sal-duration="700">
                   
                    <div className="rainbow-box-card card-style-default testimonial-style-one">
                        <div className="inner">
                            <div className="thumbnail"><img loading="lazy" src="assets/images/testimonial/testimonial-01.jpg" alt="Corporate Template"/></div>
                            <div className="content">
                                <p className="description">He worked dedicatedly and communicated with me in every minute detail. I wish him the best of luck in his future endeavors.</p>
                                <h2 className="title">Sahmad </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mt--30 sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
               
                    <div className="rainbow-box-card card-style-default testimonial-style-one">
                        <div className="inner">
                            <div className="thumbnail"><img loading="lazy" src="assets/images/testimonial/testimonial-01.jpg" alt="Corporate React Template"/></div>
                            <div className="content">
                                <p className="description">Excellent experience working with ITSS. Very patient. Completed all work even though there were some challenges with the project, the seller was always there patient and with solutions.</p>
                                <h2 className="title">George</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mt--30 sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="200">
              
                    <div className="rainbow-box-card card-style-default testimonial-style-one">
                        <div className="inner">
                            <div className="thumbnail"><img loading="lazy" src="assets/images/testimonial/testimonial-01.jpg" alt="Corporate React Template"/></div>
                            <div className="content">
                                <p className="description">Exceptional work! <br></br>Thank you! <br></br>Responsive and easy to work with.</p>
                                <h2 className="title">beesols</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></>
  )
}

export default Testimonial