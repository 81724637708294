import React from 'react'
import Metatags from "../../common/Metatags";
import { Link } from "react-router-dom";
import OurMission from '../../components/OurMission';
import CTA from '../../components/CTA'

function Services() {
  return (
    <>
        {/* <Metatags pageName="services" /> */}
        {/* Header */}
        <div className="slider-area slider-style-7 height-650">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <h4 className="subtitle rainbow-sub-badge"><span className="theme-gradient">Our Services</span>
                            </h4>
                            <h1 className="title">Transform <strong className='text-green'>Your<br></br> Ideas</strong> Into Reality <br></br>With Us </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Header */}

        <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
        </div>

        {/* Start Collaborate Card Area */}
        <div className="collaborate-card-area rainbow-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title  text-left sal-animate">
                            <h3 className="title mb--20">Welcome to <strong className='text-green'>ITSS</strong> services 👋🏾</h3>
                        </div>
                    </div>
                </div>
                <div className="row row--10">

                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--20">
                      
                        <div className="service service__style--1 variation-2 variation-3 bg-lessdark-gradient radius text-left h-100">
                            <div className="content">
                                <h6 className="subtitle"><span className="theme-gradient">Service 01</span></h6>
                                <h4 className="title w-600">
                                    <a href="#">Website Design & Development</a>
                                </h4>
                                <p className="description b1 color-gray mb--0">Crafting responsive, user-friendly websites tailored to your business needs for a compelling online presence.
                                </p>
                              
                                <Link className="btn-default btn-icon mt-3" to={"/website-design-and-development"}>View In Detail
                                                <i className="feather-arrow-right"></i>
                                            </Link>
                                            
                            </div>
                        </div>
                        
                    </div>

                    <div className="col-lg-8 col-md-6 col-sm-12 col-12 mt--20">
                        <div className="service service__style--1 variation-2 variation-3 radius text-left h-100 bg_image bg_image--2">
                            <div className="content">

                            </div>

                        </div>
                    </div>

                    <div className="col-lg-8 col-md-6 col-sm-12 col-12 mt--20">
                        <div className="service service__style--1 variation-2 variation-3 radius text-left h-100 bg_image bg_image--3">
                            <div className="content">
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--20">
                    
                        <div className="service service__style--1 variation-2 variation-3 bg-lessdark-gradient radius text-left h-100">
                            <div className="content">
                                <h6 className="subtitle"><span className="theme-gradient">Service 02</span></h6>
                                <h4 className="title w-600">
                                    <a href="#">Graphic & Logo Design</a>
                                </h4>
                                <p className="description b1 color-gray mb--0">Creating stunning graphics and memorable logos that reflect your brand identity and captivate your audience.
                                </p>
                                <Link className="btn-default btn-icon mt-3" to={"/graphic-and-logo-design"}>View In Detail
                                                <i className="feather-arrow-right"></i>
                                            </Link>
                            </div>
                           
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        {/* End Collaborate Card Area */}

        <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
        </div>

        {/* Start Service Area */}
        <div className="rbt-feature-area rainbow-section-gap">
            <div className="container">
                
                <div className="row mb--40">
                    <div className="col-lg-12">
                        <div className="section-title max-width-800 text-left sal-animate">
                            <h2 className="title w-600 mb--20">Creators think we're the <br/> new <i className='text-green'>Cool</i>. Here's why;</h2>
                        </div>
                    </div>
                </div>
                <div className="row g-5">
                    
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate">
                        <div className="service service__style--1 text-left service-bg-transparent">
                            <div className="icon">
                                <i className="feather-link theme-gradient"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><a href="#">Cutting-Edge Designs</a></h4>
                                <p className="description b1 color-gray mb--0">Our agency creates websites and graphics that are not just functional but visually stunning, keeping up with the latest design trends and technologies.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate">
                        <div className="service service__style--1 text-left service-bg-transparent">
                            <div className="icon">
                                <i className="feather-user-check theme-gradient"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><a href="#">Innovative Approach</a></h4>
                                <p className="description b1 color-gray mb--0">We bring fresh ideas to the table, constantly pushing boundaries and experimenting with new techniques to deliver unique solutions to your clients.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate">
                        <div className="service service__style--1 text-left service-bg-transparent">
                            <div className="icon">
                                <i className="feather-bar-chart  theme-gradient"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><a href="#">Responsive</a></h4>
                                <p className="description b1 color-gray mb--0">Our agency is known for its quick response times and ability to adapt to changes, delivering projects on time and exceeding client expectations.</p>
                            </div>
                        </div>
                    </div>
                    

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate">
                        <div className="service service__style--1 text-left service-bg-transparent">
                            <div className="icon">
                                <i className="feather-layout theme-gradient"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><a href="#">Versatility</a></h4>
                                <p className="description b1 color-gray mb--0">Offering both website design and development as well as graphic design services makes your agency a one-stop shop for all digital creative needs, providing convenience for your clients.</p>
                            </div>
                        </div>
                    </div>

                   
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate">
                        <div className="service service__style--1 text-left service-bg-transparent">
                            <div className="icon">
                                <i className="feather-user-plus theme-gradient"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><a href="#">User-Centric Focus</a></h4>
                                <p className="description b1 color-gray mb--0">Our designs prioritize the user experience, ensuring that every website and graphic you create is intuitive, engaging, and tailored to meet the needs of your target audience.</p>
                            </div>
                        </div>
                    </div>
                
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate">
                        <div className="service service__style--1 text-left service-bg-transparent">
                            <div className="icon">
                                <i className="feather-award theme-gradient"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><a href="#">Strong Brand Identity</a></h4>
                                <p className="description b1 color-gray mb--0">Our agency has developed a strong brand presence, showcasing your unique style and personality across all your work, which resonates with clients and sets you apart from the competition.</p>
                            </div>
                        </div>
                    </div>
                
                </div>
                
            </div>
        </div>
        {/* End Service Area   */}

        <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
        </div>

        {/* Start Company Mission Area */}
        <OurMission/>
        {/* End Company Mission Area */}

        {/* Start Call To Action Area */}
       
        <CTA/>
        
        {/* End Call To Action Area */}

       
    </>
  )
}

export default Services