import React from 'react'

function OurMission() {
  return (
    <div className="rainbow-company-mission-are rainbow-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title" >
                            <h2 className="title">Our Mission <br/></h2>
                            <p>Step into ITSS where our mission is clear: to craft websites that stand out, ensure your satisfaction every step of the way, and give you the best bang for your buck. We're all about making your online presence shine, with designs that not only look great but also work seamlessly. </p>
                            <p>
                              Your happiness is our priority, so we'll work closely with you to make sure we nail every detail. And when it comes to value, we've got you covered – our services are priced competitively so you can get the quality you deserve without overspending. Trust us to bring your vision to life and exceed your expectations – that's the ITSS promise.</p>
                            
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <div className="rainbow-accordion-style accordion" >
                            <div className="accordion" id="accordionExamplea">
                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        How much does a website from ITSS cost?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">
                                        The cost of a website can vary depending on various factors such as the size, complexity, and features required. We offer transparent pricing and will work with you to create a custom solution that fits your budget and needs. Contact us today for a personalized quote tailored to your specific requirements.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                          How long does it take to build a website with ITSS?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">
                                        We understand the importance of timeliness in the digital world. The timeline for building a website can vary depending on the complexity and scope of the project. However, rest assured, we work efficiently to deliver your website in a timely manner without compromising on quality.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Do you offer ongoing support after the website is launched?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">
                                        Yes, we provide ongoing support to ensure your website continues to perform optimally. Whether you need updates, maintenance, or assistance with any issues, our team is here to help you every step of the way.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Can I see examples of websites you've designed?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">
                                        Absolutely! We'd be happy to showcase our portfolio of past projects to give you a better idea of our capabilities and style. Feel free to browse through our portfolio to see the quality of work we deliver.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default OurMission