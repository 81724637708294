import React from 'react'
import Atropos from 'atropos/react';
import { Link } from "react-router-dom";

function RecentProject() {
  return (
    <>
    <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
        </div>
    <div className="rainbow-advance-tab-area rainbow-section-gap">
            <div className="container" data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
                <div className="row mb--40">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <h4 className="subtitle "><span className="theme-gradient">Our Projects</span></h4>
                            <h2 className="title w-600 mb--20">Our Recent Web Projects</h2>
                        </div>
                    </div>
                </div>
                <div className="row row--30">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade advance-tab-content-1 active show" id="home-2" role="tabpanel" aria-labelledby="home-tab-2">
                                <div className="thumbnail">
                                    <Atropos>
                                <img loading="lazy" src="assets/images/advance-tab/tabs-03.jpg" alt="advance-tab-image"/>
                                </Atropos>
                                </div>
                            </div>
                            <div className="tab-pane fade advance-tab-content-1" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                <div className="thumbnail">
                                <Atropos>
                                    <img loading="lazy" src="assets/images/advance-tab/tabs-02.jpg" alt="advance-tab-image"/>
                                    </Atropos>
                                </div>
                            </div>
                            <div className="tab-pane fade advance-tab-content-1" id="contact-2" role="tabpanel" aria-labelledby="contact-tab-2">
                                <div className="thumbnail">
                                <Atropos>
                                <img  loading="lazy" src="assets/images/advance-tab/tabs-04.jpg" alt="advance-tab-image"/>
                                </Atropos>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <ul className="nav nav-tabs tab-button-list" id="myTab-2" role="tablist">
                                <li className="nav-item " role="presentation">
                                    <a href="#" className=" ShineButton nav-link tab-button tabs__tab--selected active" id="home-tab-2" data-bs-toggle="tab" data-bs-target="#home-2" role="tab" aria-controls="home-2" aria-selected="true">
                                        <div className="tab ">
                                            <h4 className="title">SimWealth – Wealth Made Simple</h4>
                                            <p className="description">Lifetime making financial decisions system.</p>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a href="#" className="ShineButton nav-link tab-button tabs__tab--selected" id="profile-tab-2" data-bs-toggle="tab" data-bs-target="#profile-2" role="tab" aria-controls="profile-2" aria-selected="false">
                                        <div className="tab">
                                            <h4 className="title">Facilities4u - Cleaning services company</h4>
                                            <p className="description">Cleaning services company in Bangalore online service provider.</p>
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <a href="#" className="ShineButton nav-link tab-button tabs__tab--selected" id="contact-tab-2" data-bs-toggle="tab" data-bs-target="#contact-2" role="tab" aria-controls="contact-2" aria-selected="false">
                                        <div className="tab">
                                            <h4 className="title">Swadharma Consultancy</h4>
                                            <p className="description">Swadharma Consultancy offers comprehensive support to help entrepreneurs overcome common obstacles in starting a new business.</p>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mt--10" data-aos="fade-up">
                    <Link className="btn-default w-75 btn-large btn-border" to={"/services"}>Explore Services <i className="feather-arrow-right"></i></Link>
                    
                </div>
        </div>
        </>
  )
}

export default RecentProject