import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <><main className="page-wrapper">
          <Navbar />
          {children}
          <Footer />
      </main>
      <div className="rainbow-back-top">
        <i className="feather-arrow-up"></i>
      </div>
    </>
    
  );
};

export default Layout;