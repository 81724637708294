import React from 'react'
import Metatags from "../common/Metatags";
import Atropos from 'atropos/react';
import { Link } from "react-router-dom";

function GraphicPricing() {
  return (
    <>
        {/* <Metatags pageName="graphic-pricing" />  */}
        <div className="rainbow-pricingtable-area rainbow-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center" data-aos="fade-up" >
                            <h4 className="subtitle "><span className="theme-gradient">Our Pricing</span></h4>
                            <h2 className="title w-600 mb--20">Our Graphic Pricing</h2>
                            <p className="description b1">Explore our range of offerings tailored to meet your design needs. While there are many options out there, trust in ITSS for unparalleled creativity and expertise.</p>
                        </div>
                    </div>
                </div>
                <div className="row mt--30" data-aos="fade-up">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="advance-pricing">
                            <div className="inner">
                                <div className="row row--0">
                                    <div className="col-lg-6">
                                        <div className="pricing-left">
                                            <h3 className="main-title text-center">Starter Package</h3>
                                            <p className="description text-center">Kickstart your journey with our budget-friendly option, perfect for those seeking essential graphic design services without compromising on quality.</p>
                                            <div className="price-wrapper">
                                                <span className="price-amount">$20</span>
                                            </div>
                                            <div className="pricing-btn-group">
                                                <Link to={"/contact"}>
                                                <button className="btn-default">Contact Now</button>
                                                </Link>
                                            </div>
                                            <div className="rating">
                                                <a href="#rating">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </a>
                                                <a href="#rating">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </a>
                                                <a href="#rating">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </a>
                                                <a href="#rating">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </a>
                                                <a href="#rating">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <span className="subtitle">rated 4.9/5 Stars in 100+ reviews.</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="pricing-right">
                                            <div className="pricing-offer">
                                                <div className="single-list mt--50">
                                                    <h4 className="price-title">Starter Package You can Get.</h4>
                                                    <ul className="plan-offer-list">
                                                        <li>
                                                            <i className="feather-check"></i> Logo Design
                                                        </li>
                                                        <li>
                                                            <i className="feather-check"></i> Visiting Card Design
                                                        </li>
                                                        <li>
                                                            <i className="feather-check"></i> Letterhead Design
                                                        </li>
                                                        <li>
                                                            <i className="feather-check"></i> Envelope Design
                                                        </li>
                                                        <li>
                                                            <i className="feather-check"></i> Envelope Cover Design
                                                        </li>
                                                        <li>
                                                            <i className="feather-check"></i> 2 Social Media Posts
                                                        </li>
                                                        <li>
                                                            <i className="feather-check"></i> 3 Different Mockups
                                                        </li>
                                                        <li>
                                                            <i className="feather-check"></i> Deliverables in PDF, SVG, PSD, JPG, and PNG Formats
                                                        </li>
                                                        <li>
                                                            <i className="feather-check"></i> Logo in Black and White, Provided as Two Separate Files
                                                        </li>
                                                    </ul>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="rainbow-callto-action-area rainbow-section-gapBottom">
            <div className="wrapper">
                <div className="rainbow-callto-action clltoaction-style-default style-5">
                    <div className="container">
                        <div className="row row--0 align-items-center content-wrapper pb--0">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="content text-center">
                                        <h2 className="title sal-animate" >Getting started <br/> with <strong className='text-green'>ITSS</strong> Services</h2>
                                        <p>We're excited to embark on this creative journey with you. <br></br>Whether you're seeking captivating logos, eye-catching branding,or vibrant graphic designs. </p>

                                        <div className="call-to-btn sal-animate button-group">
                                            <Link className="btn-default btn-medium btn-border round btn-icon" to={"/contact"}>Contact Now <i className="icon feather-arrow-right"></i>
                                            </Link>
                                        </div>

                                        <div className="image mt--50">
                                                <Atropos>
                                                <img loading="lazy" src="assets/images/advance-tab/tab-screen-01.png" alt="card Images"/>
                                                </Atropos>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default GraphicPricing