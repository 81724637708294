import React,{useEffect,useState} from 'react'
import serviceData from '../data/services.json'
import { Link } from "react-router-dom";

function Services() {

    const [servicesData,setServicesData] = useState([]);
    useEffect(()=>{
        setServicesData(serviceData)
    },[])

  return (
    <>
        <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
        </div>
        <div className="rainbow-service-area rainbow-section-gap ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
                            <h4 className="subtitle">
                                <span className="theme-gradient">What we can do for you</span>
                            </h4>
                            <h2 className="title w-600 mb--20 text-primary">Services provide for you.</h2>
                            <p className="description b1">Explore our services in web development and application development,<br></br>offering tailored solutions to meet your unique requirements. </p>
                        </div>
                    </div>
                </div>

                <div className="row row--15 service-wrapper" data-aos="fade-up">
                {
                    servicesData.map((e,index)=>
                        <div key={index} className="col-lg-6 col-md-6 col-sm-6 col-12 mb--25  sal-animate" data-sal="slide-up" data-sal-duration="700">
                            <div className="service service__style--1 bg-color-blackest radius text-center rbt-border-none">
                                <div className="icon">
                                    
                                    <i className="feather-activity"></i>
                                </div>
                                <div className="content">
                                    <h4 className="title w-600">
                                        <Link to={"/services"}>{e.ServiceName}</Link>
                                    </h4>
                                    <p className="description b1 color-gray mb--0">{e.Description}</p>
                                </div>
                            </div>
                        </div>    
                    )
                }
                </div>
            </div>
        </div>
        
    </>
    )
}

export default Services