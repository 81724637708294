import React from 'react'
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../lotties/website.json';

function Slider() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

  return (
    <>
     <div className="slider-area slider-style-1 bg-transparent variation-2 height-750 ">
            <div className="container ">
                <div className="row">
                    <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                        <div className="inner text-left">
                            
                            <h1 className="title theme-gradient display-two">Experience the Future For Web Development</h1>
                        
                            <p className="description">Embark on a journey with us, where innovation meets excellence. Experience the future of software development</p>
                            <div className="button-group">
                                <Link className="btn-default btn-medium round btn-icon" to={"/about"}>New
                                    Customer <i className="icon feather-arrow-right"></i></Link>
                                <Link className="btn-default btn-medium btn-border round btn-icon" to={"/contact"}>Contact Us <i
                                        className="icon feather-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 order-1 order-lg-2">
                        <div className="thumbnail">
                        
                            {/* <img loading="lazy" src="assets/images/bg/bg-image-16.png" alt="Banner Images"/> */}
                            
                            <Lottie  options={defaultOptions} height={520}  />
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        </>
  )
}

export default Slider