import React from 'react'
import Atropos from 'atropos/react';
import { Link } from 'react-router-dom';

function AboutBusiness() {
  return (
    <>
     <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
        </div>
    <div className="rainbow-about-area rainbow-section-gap" >
            <div className="container">
                <div className="row row--30 align-items-center" data-aos="fade-up">
                    <div className="col-lg-5">
                        <div className="thumbnail sal-animate" data-sal="slide-right" data-sal-duration="700">
                            <Atropos>
                                <img className="w-100" loading="lazy" src="assets/images/about/about-1.png" alt="About Images"/>
                            </Atropos>
                        </div>
                    </div>
                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content sal-animate" data-sal="slide-left" data-sal-duration="700">
                            <div className="section-title">
                                <h4 className="subtitle"><span className="theme-gradient">MEET ITSS</span></h4>
                                <h2 className="title mt--10">About Our Business.</h2>

                                <p>Welcome to ITSS (information technology solutions scads), your innovation hub for website and web app development and graphic & logo design. We're not just an IT service startup; we're your tech partner, turning ideas into interactive experiences. From cutting-edge websites to sleek mobile apps, we're here to transform your vision into a digital reality. Let's build something extraordinary together – where your digital journey begins!</p>

                                <ul className="list-icon">
                                    <li><span className="icon"><i className="feather-check"></i></span> Empower your ideas with ITSS</li>
                                    <li><span className="icon">
                                            <i className="feather-check"></i>
                                        </span> Elevate your digital presence</li>
                                    <li><span className="icon">
                                            <i className="feather-check"></i>
                                        </span> User-Centric Approach</li>
                                    <li><span className="icon">
                                            <i className="feather-check"></i>
                                        </span> Dive into a realm of limitless possibilities</li>
                                </ul>

                                <div className="read-more-btn mt--40">
                                    <Link className="btn-default btn-icon" to={"/about"}>More About Us <i className="icon feather-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>

     
    </>
  )
}

export default AboutBusiness