import React from 'react'
import { Link } from 'react-router-dom'

function portfolioAd() {
  return (
    <>
    <div className="footer-style-3">
                <div className="rainbow-callto-action rainbow-call-to-action style-8 content-wrapper">
                    <div className="container">
                        <div className="row row--0 align-items-center ">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="content text-center">
                                        <h2 className="title">Ready to Explore Our Portfolio?</h2>
                                        <h6 className="subtitle">The Ultimate Selection for Your Online Presence and Visual Needs</h6>
                                        <div className="call-to-btn text-center mt--30">
                                            <Link className="btn-default btn-icon mr--10 mb--10" to={"/website-portfolio"}>View Website Portfolio
                                            <i className="icon feather-arrow-right"> </i></Link>
                                            
                                            <Link className="btn-default btn-icon mr--10 mb--10" to={"/graphic-portfolio"}>View Graphic Portfolio
                                            <i className="icon feather-arrow-right"> </i></Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        </>
  )
}

export default portfolioAd