import React from 'react'
import Metatags from "../common/Metatags";
import Atropos from 'atropos/react';
import { Link } from "react-router-dom";

function WebPricing() {
  return (
    <>
        {/* <Metatags pageName="website-pricing" />  */}

        <div className="rainbow-pricing-area rainbow-section-gap">
            <div className="container">
                <div className="row mb--40 mb_sm--0" data-aos="fade-up">
                    <div className="col-lg-12">
                        <div className="section-title text-center" >
                            <h4 className="subtitle "><span className="theme-gradient">Our Pricing</span></h4>
                            <h2 className="title w-600 mb--20">Our Website Pricing</h2>
                        </div>
                    </div>
                </div>
                <div className="row row--15" data-aos="fade-up">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="rainbow-pricing style-2">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Starter Website</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper"><span className="currency">$</span><span
                                                className="price">180</span></div><span className="subtitle">Perfect for individuals or small businesses looking to establish their online presence.</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><i className="feather-check"></i> Customizable website</li>
                                        <li><i className="feather-check"></i> Digital Business Card</li>
                                        <li><i className="feather-check"></i> Up to 5 pages (Home, About, Services, Contact, Gallery) (Without hosting and domain)</li>
                                        <li><i className="feather-check"></i> Basic contact form integration</li>
                                        <li><i className="feather-check"></i> Mobile-responsive design</li>
                                        <li><i className="feather-check"></i> Basic SEO setup</li>
                                        <li><i className="feather-check"></i> Email support</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer"><Link className="btn-default btn-border" to={"/contact"}>Contact Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="rainbow-pricing style-2 active">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Advanced Website</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper"><span className="currency">$</span><span
                                                className="price">421</span></div><span className="subtitle">Designed for businesses looking to sell products or services online and maximize their digital revenue.</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                    <li><i className="feather-check"></i> Custom e-commerce website with user-friendly interface</li>
                                        <li><i className="feather-check"></i> Unlimited product listings</li>
                                        <li><i className="feather-check"></i> Secure payment gateway integration (PayPal, Stripe, etc.)</li>
                                        <li><i className="feather-check"></i> Advanced shipping and tax settings</li>
                                        <li><i className="feather-check"></i> Customer account creation and management</li>
                                        <li><i className="feather-check"></i> Mobile-optimized shopping experience</li>
                                        <li><i className="feather-check"></i> Comprehensive SEO for product pages</li>
                                        <li><i className="feather-check"></i> Integration with inventory management system (if applicable)</li>
                                        <li><i className="feather-check"></i> Three rounds of revisions</li>
                                        <li><i className="feather-check"></i> Priority email, phone, and chat support</li>

                                    </ul>
                                </div>
                                <div className="pricing-footer"><Link className="btn-default " to={"/contact"}>Contact Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="rainbow-pricing style-2 ">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Professional Website</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper"><span className="currency">$</span><span
                                                className="price">240</span></div><span className="subtitle">Ideal for businesses aiming to make a significant impact online with enhanced features and functionality.</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><i className="feather-check"></i> Custom-designed website tailored to your brand</li>
                                        <li><i className="feather-check"></i> Up to 10 pages (Home, About, Services, Contact, Portfolio, Blog, etc.) </li>
                                        <li><i className="feather-check"></i> Advanced contact</li>
                                        <li><i className="feather-check"></i> Mobile-responsive design</li>
                                        <li><i className="feather-check"></i> Domain registration and setup</li>
                                        <li><i className="feather-check"></i> SEO optimization (keyword research, on-page SEO)</li>
                                        <li><i className="feather-check"></i> Integration of social media links</li>
                                        <li><i className="feather-check"></i> Priority email and phone support</li>
                                    </ul>
                                </div>
                                
                                <div className="pricing-footer"><Link className="btn-default btn-border" to={"/contact"}>Contact Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
        <div className="rainbow-callto-action-area rainbow-section-gapBottom">
            <div className="wrapper">
                <div className="rainbow-callto-action clltoaction-style-default style-5">
                    <div className="container">
                        <div className="row row--0 align-items-center content-wrapper pb--0">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="content text-center">
                                        <h2 className="title sal-animate" >Getting started <br/> with <strong className='text-green'>ITSS</strong> Services</h2>
                                        <p>Welcome aboard! We're thrilled that you've chosen ITSS for your digital needs. <br></br>Whether you're looking to build a stunning website, revamp your branding, or enhance your online presence.</p>

                                        <div className="call-to-btn sal-animate button-group">
                                            <Link className="btn-default btn-medium btn-border round btn-icon" to={"/contact"}>Contact Now <i className="icon feather-arrow-right"></i>
                                            </Link>
                                        </div>

                                        <div className="image mt--50">
                                                <Atropos>
                                                <img loading="lazy" src="assets/images/advance-tab/tab-screen-03.png" alt="card Images"/>
                                                </Atropos>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default WebPricing