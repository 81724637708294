import React,{useRef,useState } from 'react'
import Metatags from "../common/Metatags";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
    const formRef = useRef(null);    
    const _phone = process.env.REACT_APP_PHONE;
    const _email = process.env.REACT_APP_EMAIL;
    const _address = process.env.REACT_APP_ADDRESS;
    const [errors, setErrors] = useState({});
    const sendEmail=(e)=>{
        e.preventDefault();
        if (formRef.current.reportValidity()) {
            emailjs.sendForm('service_swobdkf','template_jjgcjlx',formRef.current,'CnCmKApOmX2WNghWs')
            .then((result) => {
                toast.success('Email sent successfully!');
                formRef.current.reset();
            }, (error) => {
                toast.error('Failed to send email. Please try again later.');
            });
        }else{
            const formErrors = {};
            const formElements = formRef.current.elements;
            for (let i = 0; i < formElements.length; i++) {
                const field = formElements[i];
                if (field.nodeName === 'INPUT' || field.nodeName === 'TEXTAREA') {
                if (!field.validity.valid) {
                    formErrors[field.name] = field.validationMessage;
                }
                }
            }
            setErrors(formErrors);
        }
    }

  return (
    <>
        {/* <Metatags pageName="contact" /> */}

        <div className="rainbow-contact-area rainbow-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb--40">
                            <div className="section-title text-center" data-aos="fade-up">
                                <h4 className="subtitle "><span className="theme-gradient">Contact Form</span></h4>
                                <h2 className="title w-600 mb--20">Our Contact Address Here.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row row--15">
                        <div className="col-lg-12">
                            <div className="rainbow-contact-address mt_dec--30">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="rainbow-address">
                                            <div className="icon">
                                                <i className="feather-headphones"></i>
                                            </div>
                                            <div className="inner mb-5">
                                                <h4 className="title">Contact Phone Number</h4>
                                                <p><a href="#">{_phone}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="rainbow-address">
                                            <div className="icon">
                                                <i className="feather-mail"></i>
                                            </div>
                                            <div className="inner mb-5">
                                                <h4 className="title">Our Email Address</h4>
                                                <p><a href={`mailto:${_email}`}>{_email}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="rainbow-address">
                                            <div className="icon">
                                                <i className="feather-map-pin"></i>
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Our Location</h4>
                                                <p>{_address}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt--40 row--15">
                        <div className="col-lg-7">
                            <form ref={formRef} className="contact-form-1 rainbow-dynamic-form" onSubmit={sendEmail}>
                                <div className="form-group">
                                    <input type="text" name="ContactName" id="ContactPhone" placeholder="Your Name" required='required'/>
                                    {errors['ContactName'] && <div className="error">{errors['ContactName']}</div>}
                                </div>
                                <div className="form-group">
                                    <input type="text" name="ContactPhone" id="ContactPhone" placeholder="Phone Number" minLength={10} maxLength={10} required='required'/>
                                    {errors['ContactPhone'] && <div className="error">{errors['ContactPhone']}</div>}
                                </div>
                                <div className="form-group">
                                    <input type="email" id="ContactEmail" name="ContactEmail" placeholder="Your Email" required='required'/>
                                    {errors['ContactEmail'] && <div className="error">{errors['ContactEmail']}</div>}
                                </div>

                                <div className="form-group">
                                    <input type="text" id="ContactSubject" name="ContactSubject" placeholder="Your Subject" required='required'/>
                                    {errors['ContactSubject'] && <div className="error">{errors['ContactSubject']}</div>}
                                </div>

                                <div className="form-group">
                                    <textarea name="ContactMessage" id="ContactMessage" placeholder="Your Message" required='required'></textarea>
                                    {errors['ContactMessage'] && <div className="error">{errors['ContactMessage']}</div>}
                                </div>

                                <div className="form-group">
                                    <button name="submit" type="submit" id="submit" className="btn-default btn-large rainbow-btn">
                                        <span>Submit Now</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5 mt_md--30 mt_sm--30">
                            <div className="google-map-style-1">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115668.8927549577!2d73.8026357820266!3d25.045850903004304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39686b839bae6cf5%3A0x12b788e27ab3c55b!2sRajsamand%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1707538867987!5m2!1sen!2sin" width="600" height="450" style={{border:0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
    </>
  )
}

export default Contact