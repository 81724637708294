import React from 'react'
import { Link } from "react-router-dom";

function Error() {
  return (
    <>
    <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
            <div className="row align-item-center">
                <div className="col-lg-12">
                    <div className="error-inner">
                        
                            <h1>404</h1>
<h2 className="title">Oops! Something went wrong.</h2>
<p className="description">We couldn't find the page you're looking for. It may have been moved, deleted,<br></br> or never existed in the first place. Please check the URL or navigate back to the <a href="/">homepage</a>.</p>

                        <Link className="btn-default" to={"/"}>Go Back Home</Link></div>
                    </div>
                </div>
            </div>
        </div>
    
    </>
  )
}

export default Error