import React,{useState,useEffect} from 'react'
import Atropos from 'atropos/react';
import { Link } from 'react-router-dom';
import graphicportfolio_dt from '../../data/graphicportfolio.json'
import Metatags from "../../common/Metatags";

function GraphicPortfolio() {
    
    const [graphicportfolio,setGraphicportfoilio] = useState([]);
    useEffect(()=>{
        setGraphicportfoilio(graphicportfolio_dt);
    },[])

    // var html =""
    // graphicportfolio.map((e)=>{
    //     html+= `<div className="portfolio-4 cat--${e.Category}">
    //                 <div className="rainbow-card portfolio">
    //                     <div className="inner">
    //                         <div className="thumbnail">
    //                             <figure className="card-image">
    //                                     <img src="${e.ImageUrl}" alt="${e.Alt}" loading='Lazy' />
    //                             </figure>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>`
    // })
    

  return (
    <>
     {/* <Metatags pageName="graphic-portfolio" />  */}
    <div className="slider-area slider-style-6 shape-left inheader-not-transparent height-650">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                        <div className="inner text-left">
                            <h1 className="title color-white">Explore<br></br> Our <strong className='text-green'>Graphic</strong>  Portfolio</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="video-btn">
                            <div className="video-popup icon-center">
                                <div className="overlay-content">
                                    <Atropos>
                                    <div className="thumbnail"><img className="radius-small" loading='Lazy' src="assets/images/graphic/main-heading.png" alt="Corporate Image"/></div>
                                    </Atropos>
                                </div>
                            </div>
                            <div className="video-lightbox-wrapper"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-image"><img loading='Lazy' src="assets/images/banner/white-shape.svg" alt="Banner Images"/></div>
        </div>

        <div className="rainbow-portfolio-area rainbow-section-gapBottom mt--50 masonary-wrapper-activation">
            <div className="wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="rainbow-portfolio-filter filter-button-default messonry-button text-center mb--60">
                            <button data-filter="*" className="is-checked"><span className="filter-text">All</span></button>
                            <button data-filter=".cat--logo"><span className="filter-text">Logo</span></button>
                            <button data-filter=".cat--stationary"><span className="filter-text">Stationary</span></button>
                            <button data-filter=".cat--banner"><span className="filter-text">Banners/Flayer</span></button>
                            <button data-filter=".cat--other"><span className="filter-text">Other</span></button>
                        </div>
                        <div className="portfolio-items grid-metro4 mesonry-list no-gutter">
                            <div className="resizer"></div>
                            <div className="portfolio-4 cat--logo">
                    <div className="rainbow-card portfolio">
                        <div className="inner">
                            <div className="thumbnail">
                                <figure className="card-image">
                                        <img src="assets/images/graphic/logo-1.jpg" alt="ITSS Logo design" loading='Lazy' />
                                </figure>
                            </div>
                        </div>
                    </div>
                            </div><div className="portfolio-4 cat--logo">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/logo-2.jpg" alt="ITSS Logo design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--logo">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/logo-3.jpg" alt="ITSS Logo design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--banner">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/banner-1.jpg" alt="ITSS banner design, Banner, flayer" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--banner">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/banner-2.jpg" alt="ITSS banner design, Banner, flayer" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--banner">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/banner-3.jpg" alt="ITSS banner design, Banner, flayer" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--banner">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/banner-4.jpg" alt="ITSS banner design, Banner, flayer" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--banner">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/banner-5.jpg" alt="ITSS banner design, Banner, flayer" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--logo">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/logo-4.jpg" alt="ITSS Logo design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--logo">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/logo-5.jpg" alt="ITSS Logo design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--logo">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/logo-6.jpg" alt="ITSS Logo design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--logo">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/logo-7.jpg" alt="ITSS Logo design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--logo">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/logo-8.jpg" alt="ITSS Logo design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--logo">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/logo-9.jpg" alt="ITSS Logo design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-1.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-2.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-3.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-4.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-5.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-10.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-6.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-7.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-8.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--stationary">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/stationary-9.jpg" alt="ITSS stationary design, visiting card,Letterhead, Enevelop,Enevelop Conver" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--other">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/other-1.jpg" alt="ITSS T-shar, social media post,social media story design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--other">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/other-2.jpg" alt="ITSS T-shar, social media post,social media story design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--other">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/other-3.jpg" alt="ITSS T-shar, social media post,social media story design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div><div className="portfolio-4 cat--other">
                                <div className="rainbow-card portfolio">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <figure className="card-image">
                                                    <img src="assets/images/graphic/other-4.jpg" alt="ITSS T-shar, social media post,social media story design" loading='Lazy' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-style-3">
                <div className="rainbow-callto-action rainbow-call-to-action style-8 content-wrapper">
                    <div className="container">
                        <div className="row row--0 align-items-center ">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="content text-center">
                                        <h2 className="title">Explore Our Website Portfolio?</h2>
                                        <h6 className="subtitle">The Finest Selection for Your Online Presence</h6>
                                        <div className="call-to-btn text-center mt--30"><Link className="btn-default btn-icon" to={"/website-portfolio"}>View Website Portfolio<i className="icon feather-arrow-right"> </i></Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </>
  )
}

export default GraphicPortfolio