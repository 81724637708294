import React from 'react'
import Metatags from "../../common/Metatags";
import Atropos from 'atropos/react';
import { Link } from 'react-router-dom';


function WebDevelopmentService() {
  
  return (
    <>
        {/* <Metatags pageName="website-design-and-development" />  */}
        {/* Start Slider Area*/}
        <div className="slider-area slider-style-1 variation-3 bg-transparent rainbow-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <h1 className="display-two">Welcome to <strong className='text-green'>ITSS</strong> <br/> Website Design and Development Services</h1>
                            <p className="description">At ITSS, we specialize in creating stunning, functional, and user-friendly websites tailored to meet your unique business needs. </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="rainbow-default-tab style-two rbt-alignwide">
                            <div className="rbt-inner-alignwide pb--60">

                                <ul className="nav nav-tabs tab-button justify-content-center mb--50" role="tablist">
                                    <li className="nav-item tabs__tab" role="presentation">
                                        <button className="nav-link active" id="irin-tab" data-bs-toggle="tab" data-bs-target="#irin" type="button" role="tab" aria-controls="irin" aria-selected="true">Website</button>
                                    </li>

                                    <li className="nav-item tabs__tab" role="presentation">
                                        <button className="nav-link" id="nipa-tab" data-bs-toggle="tab" data-bs-target="#nipa" type="button" role="tab" aria-controls="nipa" aria-selected="false">Web Tool</button>
                                    </li>

                                    <li className="nav-item tabs__tab" role="presentation">
                                        <button className="nav-link" id="supa-tab" data-bs-toggle="tab" data-bs-target="#supa" type="button" role="tab" aria-controls="supa" aria-selected="false">Landing Page</button>
                                    </li>
                                </ul>
                                <div className="rainbow-tab-content tab-content text-center rainbow-no-padding">
                                    <div className="tab-pane fade  active show" id="irin" role="tabpanel" aria-labelledby="irin-tab">
                                        <Atropos>
                                        <div className="thambnail">
                                            <img loading="lazy" className="radius" src="assets/images/website/web-2.png" alt="tab"/>
                                        </div>
                                        </Atropos>
                                    </div>

                                    <div className="tab-pane fade" id="nipa" role="tabpanel" aria-labelledby="profile-tab">
                                        <Atropos>
                                        <div className="thambnail">
                                            <img loading="lazy" className="radius" src="assets/images/website/web-3.png" alt="tab"/>
                                        </div>
                                        </Atropos>
                                    </div>

                                    <div className="tab-pane fade" id="supa" role="tabpanel" aria-labelledby="contact-tab">
                                      <Atropos>
                                        <div className="thambnail">
                                            <img loading="lazy" className="radius" src="assets/images/website/web-1.png" alt="tab"/>
                                        </div>
                                        </Atropos>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Slider Area */}

        {/* Start Feature Area */}
        <div className="rbt-feature-area rainbow-section-gapBottom">
            <div className="container">
                <div className="row mb--40">
                    <div className="col-lg-12">
                        <div className="section-title max-width-800 text-left">
                            <h4 className="text-green">Popular Feature</h4>
                            <h2 className="title w-600 mb--20">ITSS Crafting Innovative Web Solutions for Your Business</h2>
                            <p className="description b1">At ITSS, we specialize in crafting innovative web solutions designed to elevate your business's online presence. Our team combines creativity, technical expertise, and industry-leading technologies to deliver bespoke websites, web tools, and landing pages that drive results. From concept to implementation, we're dedicated to bringing your vision to life and helping your business thrive in the digital landscape.
                            </p>
                        </div>
                    </div>
                </div>
             
                <div className="feature-main">
                   
                    <div className="rbt-feature-main bg-lessdark-gradient mb-5 padding-top-left-50">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                                <div className="feature-left-top">
                                    <h3 >Website Development</h3>
                                    <p>Our team of expert developers specializes in creating dynamic, user-friendly websites that not only reflect your brand identity but also engage your audience effectively. Whether you need a simple informational site, a robust e-commerce platform, or a complex web application, we have the skills and experience to bring your vision to life.</p>
                                </div>
                                <div className="feature-left-bottom">
                                    <div className="button-group">
                                        <a className="btn-default btn-small btn-border" href="#">Static</a>
                                        <a className="btn-default btn-small btn-border" href="#">Dynamic</a>
                                        <a className="btn-default btn-small btn-border" href="#">E-commerce</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="thumbnail">
                                <Atropos>
                                    <img loading="lazy" src="assets/images/website/laptop-website.png" alt=""/>
                                    </Atropos>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    
                    <div className="rbt-feature-main bg-lessdark-gradient mb-5 padding-top-left-50">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                                <div className="feature-left-top">
                                    <h3 >Web Tool Development</h3>
                                    <p>Looking to streamline your business processes or enhance user experience? Our web tool development services are tailored to meet your unique requirements. From customer relationship management (CRM) systems to custom web applications, we leverage the latest technologies to develop efficient, scalable solutions that empower your business to succeed.</p>
                                </div>
                                <div className="feature-left-bottom">
                                    <div className="button-group">
                                        <a className="btn-default btn-small btn-border" href="#">Image-Cut Tool</a>
                                        <a className="btn-default btn-small btn-border" href="#">Loan Calculators</a>
                                        <a className="btn-default btn-small btn-border" href="#">Scheduler</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="thumbnail">
                                <Atropos>
                                    <img loading="lazy" src="assets/images/website/laptop-webtool.png" alt=""/>
                                    </Atropos>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                   
                    <div className="rbt-feature-main bg-lessdark-gradient padding-top-left-50">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                                <div className="feature-left-top">
                                    <h3 >Landing Page Design</h3>
                                    <p>Make a lasting impression with captivating landing pages designed to convert visitors into customers. Our skilled designers craft visually stunning, conversion-focused landing pages that effectively communicate your message and drive action. Whether you're launching a new product, promoting a special offer, or driving traffic to your website, we create landing pages that deliver results.</p>
                                </div>
                                <div className="feature-left-bottom">
                                    <div className="button-group">
                                        <a className="btn-default btn-small btn-border" href="#">Product</a>
                                        <a className="btn-default btn-small btn-border" href="#">Events</a>
                                        <a className="btn-default btn-small btn-border" href="#">Lead Generation</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="thumbnail">
                                <Atropos>
                                    <img loading="lazy" src="assets/images/website/laptop-landing-page.png" alt=""/>
                                    </Atropos>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="mt--60 text-center">
                    <Link className="btn-default btn-large btn-border" to={"/website-portfolio"}>View portfolio <i className="feather-arrow-right"></i></Link>
                    <p className="mt--30 mb--0 text-white">Explore our diverse portfolio, showcasing our expertise,<br></br> creativity, and commitment to excellence.
                    </p>
                </div>
            </div>
        </div>
        {/* End Feature Area */}

        {/* Start template slider area */}
        <div className="template-slider mb--50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="bg-color-lessdark bg-lessdark-gradient theme-shape rbt-alignwide rainbow-section-gap radius">
                            <div className="rbt-inner-alignwide">
                                <div className="wrapper template-slider-wrapper mb--60">
                                    
                                    <h3 className="title text-green">Why Every Business Needs A Website</h3>
                                    <p className="description">Every business needs a website to thrive in today's digital landscape. A website serves as the cornerstone of your online presence, providing a platform for potential customers to discover, learn about, and engage with your products or services. It's more than just a virtual storefront; it's a powerful tool for building credibility, establishing trust, and expanding your reach to a global audience. With a website, you can showcase your brand, highlight your offerings, and connect with customers 24/7, regardless of their location or time zone. In a world where consumers increasingly turn to the internet to research and make purchasing decisions, having a website isn't just beneficial—it's essential for staying competitive and growing your business.</p>
                                </div>
                                <div className="wrapper template-slider-wrapper mb--60">
                                    <h3 className="title text-green">Online Presence</h3>
                                    <p className="description">In today's digital age, having a website is essential for establishing your presence online. It serves as your virtual storefront, allowing potential customers to find and learn more about your business 24/7, regardless of their location.</p>
                                </div>
                                <div className="wrapper template-slider-wrapper mb--60">
                                    <h3 className="title text-green">Brand Visibility</h3>
                                    <p className="description">A website is an excellent platform for showcasing your brand identity, including your logo, colors, messaging, and values. It helps reinforce your brand image and makes it easier for customers to recognize and remember your business.</p>
                                </div>
                                <div className="wrapper template-slider-wrapper mb--60">
                                    <h3 className="title text-green">Marketing and Advertising</h3>
                                    <p className="description">Your website serves as a central hub for all your marketing efforts. It allows you to promote your products or services, share valuable content, and engage with your target audience through various channels such as social media, email campaigns, and search engine optimization (SEO).</p>
                                </div>
                                <div className="wrapper template-slider-wrapper mb--60">
                                    <h3 className="title text-green">Accessibility and Convenience</h3>
                                    <p className="description">Unlike a physical store or office, your website is accessible to anyone with an internet connection at any time. This convenience enables potential customers to research your offerings, make purchases, or contact you whenever it's convenient for them, increasing your business's accessibility and reach.</p>
                                </div>
                                <div className="wrapper template-slider-wrapper mb--60">
                                    <h3 className="title text-green">Competitive Advantage</h3>
                                    <p className="description">In today's competitive market, having a well-designed and optimized website can give you a significant edge over competitors who lack an online presence or have outdated websites. It allows you to stay ahead of the curve and effectively compete for customers' attention and business.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Start template slider area */}
    </>
  )
}

export default WebDevelopmentService