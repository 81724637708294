import React,{useState,useEffect} from 'react'
import CTA from '../../components/CTA'
import Metatags from "../../common/Metatags";
import Atropos from 'atropos/react';
import webportfoilio_dt from '../../data/webportfolio.json'
import { Link } from 'react-router-dom';

function WebsitePortfolio() {

    const [webportfoilio,setWebportfoilio] = useState([]);
    useEffect(()=>{
        setWebportfoilio(webportfoilio_dt)  
    },[])

  return (
    <>
     {/* <Metatags pageName="website-portfolio" /> */}
     <div className="main-content">
        <div className="rainbow-timeline-area rainbow-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center"  >
                            <h4 className="subtitle "><span className="theme-gradient">Our Portfolio</span></h4>
                            <h2 className="title w-600 mb--20">Our Website Portfolio</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="rainbow-timeline-wrapper timeline-style-one position-relative" >
                            <div className="timeline-line"></div>
                            {
                                webportfoilio.map((e,index)=>
                                        <div key={index} className="single-timeline mt--50" data-aos="fade-up">
                                <div className="timeline-dot">
                                    <div className="time-line-circle"></div>
                                </div>
                                <div className="single-content">
                                    <div className="inner">
                                        <div className="row row--30 align-items-center">
                                            <div className="col-lg-6 mt_md--40 mt_sm--40 order-2 order-lg-1">
                                                <div className="content">
                                                    <h2 className="title"  >{e.Title}</h2>
                                                    <p className="description" >{e.Description}</p>
                                                    <div className="read-morebtn"  >
                                                        <Link className="btn-default btn-large round" target='_blank' to={e.WebsiteUrl}><span>View</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 order-1 order-lg-2">
                                                <Atropos>
                                                <div className="thumbnail">
                                                    <img className="w-100" src={e.ImageUrl} alt="" loading="lazy"/>
                                                </div>
                                                </Atropos>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>

     <CTA/>
     <div className="footer-style-3">
                <div className="rainbow-callto-action rainbow-call-to-action style-8 content-wrapper">
                    <div className="container">
                        <div className="row row--0 align-items-center ">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="content text-center">
                                        <h2 className="title">Explore Our Graphic Portfolio?</h2>
                                        <h6 className="subtitle">The Ultimate Selection for Your Visual Needs</h6>
                                        <div className="call-to-btn text-center mt--30"><Link className="btn-default btn-icon" to={"/graphic-portfolio"}>View Website Portfolio<i className="icon feather-arrow-right"> </i></Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </>
  )
}

export default WebsitePortfolio