import React,{useEffect,useState} from 'react'
import featuresData from '../data/features.json'

function AwesomeFeature() {

    const [featureData,setFeaturesData] = useState([]);
    useEffect(()=>{
        setFeaturesData(featuresData)
    },[])

  return (
    <>
      <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
        </div>
        <div className="rainbow-service-area rainbow-section-gap">
            <div className="container wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
                            <h4 className="subtitle">
                                <span className="theme-gradient">OUR WEBSITE FEATURE</span>
                            </h4>
                            <h2 className="title w-600 mb--20">Awesome Feature</h2>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt--5" data-aos="fade-up">
                {
                    featureData.map((e,index)=>
                       <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 sal-animate" key={index}>
                            <div className="service service__style--1 bg-color-blackest radius text-center rbt-border h-100">
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-code">
                                        <polyline points="16 18 22 12 16 6"></polyline>
                                        <polyline points="8 6 2 12 8 18"></polyline>
                                    </svg>
                                </div>
                                <div className="content ">
                                    <h4 className="title w-600"><a href="#">{e.Title}</a></h4>
                                    <p className="description b1 color-gray mb--0">{e.Description}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
                </div>
                
            </div>
        </div>

        
    </>
    )
}

export default AwesomeFeature