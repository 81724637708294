import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
    const _address = process.env.REACT_APP_ADDRESS;

  return (
    <>
        <footer className="rainbow-footer footer-style-default variation-two">
            <div className="rainbow-callto-action clltoaction-style-default style-7">
                <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                        <div className="col-lg-8 col-md-8">
                            <div className="inner">
                                <div className="content text-left">
                                    <div className="logo">
                                        <Link to={"/"}>
                                            <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                            <img className="logo-dark" src="assets/images/logo/logo-dark.png" alt="Corporate Logo"/>
                                        </Link>
                                    </div>
                                    <p className="subtitle sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">Welcome to ITSS (information technology solutions scads), your innovation hub for website development and graphic & logo design.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                            <div className="call-to-btn text-left mt_sm--20 text-lg-right">
                                <Link className="btn-default" to={"/contact"}>Contact Now
                                    <i className="feather-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="rainbow-footer-widget">
                                <h4 className="title">Pages</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li><Link to={"/about"}>About Us</Link></li>
                                        <li><Link to={"/contact"}>Contact Us</Link></li>
                                        <li><Link to={"/services"}>Our Service</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="rainbow-footer-widget">
                                <h4 className="title">Portfolio/Pricing</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><Link to={"/website-portfolio"}>Website Portfolio</Link></li>
                                        <li><Link to={"/website-pricing"}>Website Pricing</Link></li>
                                        <li><Link to={"/graphic-portfolio"}>Graphic Portfolio</Link></li>
                                        <li><Link to={"/graphic-pricing"}>Graphic Pricing</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="rainbow-footer-widget">
                                <div className="widget-menu-top">
                                    <h4 className="title">Services</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to={"/website-design-and-development"}>Website Design <br></br>& Development</Link></li>
                                            <li><Link to={"/graphic-and-logo-design"}>Graphic & Logo<br></br> Design</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="rainbow-footer-widget">
                                <h4 className="title">Stay With Us.</h4>
                                <div className="inner">
                                    <h6 className="subtitle">{_address}</h6>
                                    <ul className="social-icon social-default justify-content-start">
                                <li>
                                    <a href="https://www.instagram.com/itsscads">
                                        <i className="feather-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/itsscads">
                                        <i className="feather-facebook"></i>
                                    </a>
                                </li> 
                                <li>
                                    <a href="https://www.instagram.com/itss.graphics">
                                        <i className="feather-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="copyright-right text-center text-lg-end">
                            <p className="copyright-text text-center">Copyright © ITSS {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer