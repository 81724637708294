import React from 'react'
import Atropos from 'atropos/react';
import { Link } from "react-router-dom";

function CTA() {
  return (
    <>
     <div className="rainbow-callto-action-area rainbow-section-gapBottom">
       
       <div className="wrapper">
      
           <div className="rainbow-callto-action clltoaction-style-default style-5">
           <Atropos>
               <div className="container">
                   <div className="row row--0 align-items-center content-wrapper theme-shape">
                       <div className="col-lg-12">
                           <div className="inner">
                               <div className="content text-center">
                                   <h2 className="title">Ready to start creating a standard website?</h2>
                                   <h6 className="subtitle">Finest choice for your home &amp; office</h6>
                                   <div className="call-to-btn">
                                       <Link className="btn-default btn-icon" to={"/contact"}>Contact ITSS
                                           <i className="feather-arrow-right"></i>
                                       </Link>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
               </Atropos>
           </div>
          
       </div>
    
   </div>
   </>
  )
}

export default CTA