import React,{useState,useEffect} from 'react'
import { Helmet,HelmetProvider  } from 'react-helmet-async';
import metatagData from '../data/metatags.json'
import { useLocation } from 'react-router-dom';

function Metatags() {  
  const location = useLocation();
  const [pageName, setPageName] = useState('');
  const [metatags, setMetaTags] = useState({
    Title: "",
    Description: "",
    Keywords: [""],
    Author: "",
    RobotsDirective: "",
    OpenGraphTitle: "",
    OpenGraphDescription: "",
    OpenGraphImageURL: ""
  });

  useEffect(() => {
    const pathname = location.pathname.replace('/', '').toLowerCase();
    setPageName(pathname || 'home');
  }, [location]);

  useEffect(() => {
    if (metatagData[pageName]) {
      setMetaTags(metatagData[pageName]);
    }
  }, [pageName]);

  return (
    <HelmetProvider>
    <Helmet>
      <title>{metatags.Title}</title>
      <meta name="language" content="English"/>
      <meta name="description" content={metatags.Description}/>
      <meta name="keywords" content={metatags.Keywords.join(", ")}/>
      <meta name="author" content={metatags.Author}/>
      <meta name="robots" content={metatags.RobotsDirective}/>
      <meta name="robots" content="max-image-preview:standard"/>
      <meta name="robots" content="max-snippet:100"/>
      <meta name="geo.country" content="US,GB,IN"/>
      <meta name="geo.region" content="US, UK, IN"/>
      <meta name="geo.placename" content="United States, United Kingdom, India"/>
      <meta name="geo.position" content="latitude;longitude"/>
      <meta name="ICBM" content="latitude, longitude"/>
      <meta property="og:title" content={metatags.OpenGraphTitle}/>
      <meta property="og:description" content={metatags.OpenGraphDescription}/>
      <meta property="og:image" content={metatags.OpenGraphImageURL}/>
      <meta property="og:type" content="website" />

      
    </Helmet>
    </HelmetProvider>
  )
}

export default Metatags