import React,{useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./constants/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import 'atropos/css'
import Layout from "./common/Layout"
import Metatags from "./common/Metatags";
//Pages
import Home from './page/Home';
import About from "./page/About";
import Contact from "./page/Contact";
import Services from "./page/Services/Services";
import Error from "./page/Error";
import WebDevelopmentService from "./page/Services/WebDevelopmentService";
import GraphicDesignService from "./page/Services/GraphicDesignService";
import WebsitePortfolio from "./page/Portfolio/WebsitePortfolio";
import GraphicPortfolio from "./page/Portfolio/GraphicPortfolio";
import WebPricing from "./page/WebPricing";
import GraphicPricing from "./page/GraphicPricing";


function App() {

  useEffect(() => { 
    AOS.init({
      offset: 100,
      duration: 800,
      easing: 'ease-in-out',
      delay: 200,
      anchorPlacement: 'center-bottom'
    });
  }, [])

  return (
    <>
      <BrowserRouter>
        <Metatags />
        <ScrollToTop>
        <Layout>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/website-design-and-development" element={<WebDevelopmentService />} />
                <Route exact path="/graphic-and-logo-design" element={<GraphicDesignService />} />
                <Route exact path="/website-portfolio" element={<WebsitePortfolio />} />
                <Route exact path="/graphic-portfolio" element={<GraphicPortfolio />} />
                <Route exact path="/website-pricing" element={<WebPricing />} />
                <Route exact path="/graphic-pricing" element={<GraphicPricing />} />
                <Route path="*" element={<Error />} />
              </Routes>
              </Layout>
            </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;