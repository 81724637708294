import React from 'react';
import Metatags from "../common/Metatags";
import Atropos from 'atropos/react';

function About() {
  return (
    <>
        {/* <Metatags pageName="about" /> */}

        <div className="slider-area slider-style-7 height-650">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <h4 className="subtitle rainbow-sub-badge"><span className="theme-gradient">About Us</span>
                            </h4>
                            <h1 className="title"><strong className='text-green'>ITSS </strong>Is A Startup <br></br> IT Business <strong className='text-green'>Agency</strong></h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="rbt-separator-mid">
            <div className="container">
                <hr className="rbt-separator m-0"/>
            </div>
        </div>
        <div className="service-area rainbow-section-gapTop">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="content">
                            <h3 className="title">Revolutionizing IT solutions for the modern era, we're your go-to IT service provider.</h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb--10">ITSS (Information Technology Solution Scands) is your premier destination for comprehensive IT services. With a steadfast dedication to excellence and a passion for innovation, we provide tailor-made solutions designed to propel your business forward in the digital age. Partner with us and experience the cutting-edge of technology-driven success.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="about-area about-style-4 rainbow-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        <div className="video-btn">
                            <div className="video-popup icon-center">
                                <div className="overlay-content">
                                    <div className="thumbnail">
                                        <Atropos>
                                            <img loading="lazy" className="radius-small" src="assets/images/about/contact-image.svg" alt="Corporate Image"/>
                                        </Atropos> 
                                    </div>                              
                                </div>
                            </div>
                            <div className="video-lightbox-wrapper"></div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title"><strong>ITSS </strong>specializes in <br/> providing tailored solutions for small corporate businesses.
                                </h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <i className="feather-check"></i>
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Embrace the mindset of continuous evolution and innovation</h4>
                                            <p className="text">Just as a good IT solution adapts to changing needs.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="feather-check"></i>
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">flexible and dynamic in approach</h4>
                                            <p className="text">Adaptable and innovative, ITSS offers dynamic solutions for your evolving needs.</p>
                                        </div>
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="rbt-separator-mid">
            <div className="container">
                <hr className="rbt-separator m-0"/>
            </div>
        </div>

        <div className="rainbow-service-area rainbow-section-gap ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
                            <h4 className="subtitle "><span className="theme-gradient">What we can do for you</span></h4>
                            <h2 className="title w-600 mb--20">Our Main Goal </h2>
                        </div>
                    </div>
                </div>
                <div className="row row--15 service-wrapper">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate" data-sal="slide-up" data-sal-duration="700">
                        <div className="service service__style--2 text-center">
                            <div className="inner">
                                <div className="image">
                                    <img loading="lazy" src="assets/images/services/icon-01.png" alt="card Images"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <a href="#">Creative Agency</a>
                                    </h4>
                                    <p className="description b1 color-gray mb--0">We specialize in transforming ideas into captivating experiences, unique solutions to elevate your brand.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
                        <div className="service service__style--2 text-center">
                            <div className="inner">
                                <div className="image">
                                    <img loading="lazy" src="assets/images/services/icon-02.png" alt="card Images"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <a href="#">Innovative Solutions</a>
                                    </h4>
                                    <p className="description b1 color-gray mb--0">We specialize in crafting unique and inventive solutions tailored to your brand's needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="200">
                        <div className="service service__style--2 text-center">
                            <div className="inner">
                                <div className="image">
                                    <img loading="lazy" src="assets/images/services/icon-03.png" alt="card Images"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <a href="#">Exceptional Quality</a>
                                    </h4>
                                    <p className="description b1 color-gray mb--0">With a focus on excellence, we consistently deliver high-quality results that exceed expectations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="rbt-separator-mid">
            <div className="container">
                <hr className="rbt-separator m-0"/>
            </div>
        </div>

        <div className="rainbow-accordion-area preview-accordion-area rainbow-section-gap">
            <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div className="row row--30">
                    <div className="col-lg-12 col-sm-12">
                        <div className="section-title text-start mb--60 sal-animate" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
                            
                            <h4 className="subtitle "><span className="theme-gradient">Have a Question?</span></h4>
                            <h2 className="title w-600 mb--20">Check out our FAQ section <br/> to see if we can help.</h2>
                        </div>
                        <div className="rainbow-accordion-style  accordion">
                            <div className="accordion" id="accordionExamplea">
                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        How long does it take to develop a website?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExamplea" >
                                        <div className="accordion-body card-body">
                                        The timeline for website development can vary depending on the complexity of the project and the specific requirements. Typically, we provide estimated timelines during the initial consultation phase based on the scope of work.

                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        What information do you need from us to start the website development process?  
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">
                                        We'll need a clear understanding of your business goals, target audience, branding guidelines, and any specific features or functionalities you require on the website. Additionally, providing content such as text, images, and videos will help expedite the development process.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Can you help with website content creation and design?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">
                                        Yes, we offer comprehensive website development services that include content creation, graphic design, and user interface design. Our team of experienced professionals will work closely with you to create engaging content and visually appealing designs that align with your brand identity.</div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What is your pricing structure for website development?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">Our pricing structure for website development is tailored to each project based on its unique requirements. We offer competitive pricing and transparent cost estimates, ensuring you know exactly what you're paying for. Feel free to contact us for a personalized quote based on your specific needs.</div>
                                    </div>
                                </div>

                                <div className="accordion-item card">
                                    <h2 className="accordion-header card-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Do you provide ongoing support and maintenance after the website is launched?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExamplea">
                                        <div className="accordion-body card-body">Yes, we offer ongoing support and maintenance services to ensure your website remains secure, up-to-date, and optimized for performance. Our team is available to address any issues, implement updates, and provide technical assistance as needed to keep your website running smoothly.
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                    

                </div>
            </div>
        </div>
    </>
  );
}

export default About;
